@import url("https://fonts.googleapis.com/css?family=Archivo:400,500,600,700&display=swap");
@import-normalize;

* {
  box-sizing: border-box;
  outline: none;
  line-height: 1.75rem;
  font-family: "Archivo", sans-serif;
}

h1, h2, h3, h4, h5, h6, p, ul, li, a {
}

svg {
  /*
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  */
}

html {
  scroll-behavior: auto;
  font-size: 18px;
  font-family: "Archivo", sans-serif;
  overflow-x: hidden;
  color: #3c3c3b;
}

html,
body,
#root {
  /** Trick again jumping when scrollbar appear */
  //margin-right: calc(100vw - 100%);
  //margin-left: 0;
  max-width: 100%;
  width: 100%;
  //width: 100vw;
  /* --------- */
  height: 100%;
  color: #3c3c3b;
}

body {
  margin: 0;
  font-family: "Archivo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

ul {
  margin: 0;
  padding: 0;
  li {
    padding: .5rem 0;
    line-height: 1.75rem;
  }
}